<template>
  <b-row>
    <b-col md="12">
      <b-card no-body>
        <b-card-body class="text-right">
          <b-button
            to="pos_configs/add"
            variant="primary"
          >
            <FeatherIcon icon="PlusIcon" />
            Oluştur
          </b-button>
        </b-card-body>
        <b-table
          responsive="sm"
          :fields="fields"
          :items="dataList"
          striped
        >
          <template #cell(title)="data">
            <div>{{ data.item.title }}</div>
            <small class="text-primary">
              {{ (data.item.default === '1')? 'Varsayılan Banka' : '' }}
            </small>
          </template>
          <template #cell(status)="data">
            <div class="text-center">
              <FeatherIcon
                v-if="data.item.status === '1'"
                class="text-success"
                icon="CheckIcon"
              />
              <FeatherIcon
                v-else
                class="text-danger"
                icon="XIcon"
              />
            </div>
          </template>
          <template #cell(control)="data">
            <b-button
              :to="'/pos_configs/edit/' + data.item.id"
              variant="outline-primary"
              size="sm"
            >
              Güncelle
            </b-button>
          </template>
        </b-table>
        <b-card-footer><b-pagination
          v-model="currentPage"
          :total-rows="dataCount"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination></b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BCardFooter, BPagination, BButton, BCardBody,
} from 'bootstrap-vue'

export default {
  name: 'UserTypes',
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
    BCardBody,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      search: '',
      fields: [
        {
          key: 'title',
          label: 'BANKA',
        },
        {
          key: 'status',
          label: 'DURUM',
          thStyle: { textAlign: 'center', width: '140px' },
        },
        {
          key: 'control',
          label: 'Kontrol',
          thStyle: { width: '140px' },
        },
      ],
      dataQuery: {
        select: [
          'pos_configs.id AS id',
          'pos_configs.status AS status',
          'pos_configs.default AS default',
          'banks.title AS title',
          'pos_types.title AS pos_type',
        ],
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      const data = this.$store.getters['posConfigs/getPos_configs']
      return data
    },
    dataCount() {
      return this.$store.getters['posConfigs/getPos_configsCount']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.$store.dispatch('posConfigs/pos_configsList', this.dataQuery)
    },
  },
}
</script>

<style scoped>
</style>
